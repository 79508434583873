import React, { useCallback } from "react"
import { Formik } from "formik"
import * as axios from "axios"
import Swal from "sweetalert2"
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input"
import Cookies from "universal-cookie"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import PhoneDropdown from "../models/phone-dropdown"
import EmailInput from "../models/email-input"
import validateEmail from "../../utils/validate-email"

const cookies = new Cookies()

const ContactForm = ({ pageContext: { translate } }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const processReCaptcha = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        return null
      }

      const token = await executeRecaptcha("submitForm")
      return token || null
    } catch (e) {
      return null
    }
  }, [executeRecaptcha])

  const onSendForm = async (
    values,
    errors,
    setSubmitting,
    setFieldValue,
    setErrors
  ) => {
    let emailValidated = await validateEmail(values.email)
    let phoneValidated = isValidPhoneNumber(values.phone)

    if (!phoneValidated) {
      return setErrors({
        ...errors,
        phone: "Invalid phone number",
      })
    }

    if (!emailValidated) {
      return setErrors({
        ...errors,
        email: "Invalid Email Address",
      })
    }

    const token = await processReCaptcha()

    if (!token) {
      return Swal.fire({
        icon: "error",
        text: translate.t?.recaptcha_failed,
        confirmButtonColor: "#30855c",
        timer: 3000,
      })
    }

    console.log(values)
    const body = {
      api_call: "/contact-us",
      data: {
        name: values.name,
        email: values.email,
        company_name: values.company_name,
        phone: values.phone,
        message: values.message,
        utm_campaign: cookies.get("utm_campaign"),
        utm_medium: cookies.get("utm_medium"),
        utm_source: cookies.get("utm_source"),
        "g-recaptcha-response": token,
      },
    }
    axios
      .post(`${process.env.GATSBY_API_URL}`, body, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          Swal.fire({
            icon: "success",
            text: translate.t?.submitted_successfully,
            confirmButtonColor: "#30855c",
            timer: 3000,
          })
          setSubmitting(false)
          setFieldValue("email", "")
          setFieldValue("name", "")
          setFieldValue("company_name", "")
          setFieldValue("phone", "")
          setFieldValue("message", "")
        } else {
          Swal.fire({
            icon: "error",
            text: translate.t?.failed_to_send,
            confirmButtonColor: "#30855c",
            timer: 3000,
          })
        }
        setSubmitting(false)
      })
      .catch(error => {
        console.log(error)
        Swal.fire({
          icon: "error",
          text: translate.t?.failed_to_send,
          confirmButtonColor: "#30855c",
          timer: 3000,
        })
        setSubmitting(false)
      })
  }

  return (
    <>
      {
        <div className="contactForm">
          <Formik
            initialValues={{
              email: "",
              name: "",
              company_name: "",
              phone: "",
              message: "",
            }}
            validate={values => {
              const errors = {}
              if (!values.email) {
                errors.email = translate.t?.required
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = translate.t?.invalid_email
              }
              if (!values.name) {
                errors.name = translate.t?.required
              }
              if (!values.company_name) {
                errors.company_name = translate.t?.required
              }
              if (!values.phone) {
                errors.phone = translate.t?.required
              }
              if (!values.message) {
                errors.message = translate.t?.required
              }
              return errors
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setErrors,
              isSubmitting,
              setSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form
                onSubmit={event => {
                  event.preventDefault()
                  onSendForm(
                    values,
                    errors,
                    setSubmitting,
                    setFieldValue,
                    setErrors
                  )
                }}
              >
                <div className="group-form form-field">
                  <label htmlFor="name">
                    {translate.t?.name}
                    <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder={translate.t?.enter_your_full_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    className={`${
                      errors.name && touched.name ? "invalid" : ""
                    }`}
                  />
                  <div className="error">
                    {errors.name && touched.name && errors.name}
                  </div>
                </div>
                <div className="group-form form-field">
                  <label htmlFor="company_name">
                    {translate.t?.company_name}
                    <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    name="company_name"
                    placeholder={translate.t?.enter_your_company_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company_name}
                    className={`${
                      errors.company_name && touched.company_name
                        ? "invalid"
                        : ""
                    }`}
                  />
                  <div className="error">
                    {errors.company_name &&
                      touched.company_name &&
                      errors.company_name}
                  </div>
                </div>
                <div className="group-form form-field">
                  <label htmlFor="email">
                    {translate.t?.email_address}
                    <sup>*</sup>
                  </label>
                  <EmailInput
                    name="email"
                    placeholder={translate.t?.enter_your_email_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={`${
                      errors.email && touched.email ? "invalid" : ""
                    }`}
                    setError={() => {
                      setErrors({
                        ...errors,
                        email: "Invalid Email Address",
                      })
                    }}
                  />
                  <div className="error">
                    {errors.email && touched.email && errors.email}
                  </div>
                </div>
                <div className="group-form form-field">
                  <label htmlFor="phone">
                    {translate.t?.phone_number}
                    <sup>*</sup>
                  </label>
                  <PhoneInput
                    name="phone"
                    countrySelectComponent={PhoneDropdown}
                    withCountryCallingCode={true}
                    international={true}
                    defaultCountry="SE"
                    placeholder={translate.t?.enter_phone_number}
                    onChange={e => {
                      if (!e) return
                      let phone = parsePhoneNumber(e)

                      if (phone) {
                        setFieldValue(
                          "phone",
                          `+${phone.countryCallingCode}-${phone.nationalNumber}`
                        )
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.phone}
                    className={`${
                      errors.phone && touched.phone ? "invalid" : ""
                    }`}
                  />
                  <div className="error">
                    {errors.phone && touched.phone && errors.phone}
                  </div>
                </div>
                <div className="group-form form-field">
                  <label htmlFor="message">
                    {translate.t?.message}
                    <sup>*</sup>
                  </label>
                  <textarea
                    type="text"
                    name="message"
                    placeholder={`${translate.t?.type_your_queries}...`}
                    rows="7"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    className={`${
                      errors.message && touched.message ? "invalid" : ""
                    }`}
                  />
                  <div className="error">
                    {errors.message && touched.message && errors.message}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btndefault greenbtn"
                  disabled={
                    isSubmitting ||
                    (errors &&
                      Object.values(errors)?.filter(e => e.length)?.length)
                  }
                >
                  {translate.t?.submit}
                </button>
              </form>
            )}
          </Formik>
        </div>
      }
    </>
  )
}

export default ContactForm
