import * as React from "react"
import ContactForm from "./contactform"
import { FaInstagram, FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa"
import mail from "../../assets/images/icons/mail.svg"
import location from "../../assets/images/icons/location.svg"

const ContactPage = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="contact_page">
          <div className="content">
            <div className="detail">
              <h1 className="heading-h2 semi text-darker">
                {translate.t?.contact_us}
              </h1>
              <p className="paragraph-p1 text-darker">
                {translate.t?.contact_heading_24h}
              </p>
              <div className="numbers">
                <div className="box">
                  <img src={mail} alt="E-mail" />
                  <p className="paragraph-p1 text-darker semi">
                    support@eraofwe.com
                  </p>
                </div>
                <div className="box">
                  <img src={location} alt="Postal Address" />
                  <p className="paragraph-p1 text-darker semi">
                    P.O. Box 2003, SE-663 11 Hammarö, Sweden{" "}
                  </p>
                </div>
              </div>
              <div className="shares">
                <a
                  href="https://www.facebook.com/eraofwe"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  <div className="icon">
                    <FaFacebook />
                  </div>
                </a>
                <a
                  href="https://twitter.com/eraofweofficial"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  <div className="icon">
                    <FaTwitter />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/accounts/login/?next=/eraofwe"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  <div className="icon">
                    <FaInstagram />
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/company/eraofwe/"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  <div className="icon">
                    <FaLinkedin />
                  </div>
                </a>
              </div>
            </div>
            <ContactForm pageContext={{ translate }} />
          </div>
        </div>
      }
    </>
  )
}

export default ContactPage
